import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import "./styles/App.css";
import { useMediaQuery } from "react-responsive";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    `Congratulations! You have connected to your wallet.`
  );
  const [total, setTotal] = useState(0.1);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const [date, setDate] = useState();

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
    getTotal();
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
    getTotal();
  };

  const getTotal = () => {
    let totalAmount = (mintAmount * 0.1).toFixed(2);
    setTotal(totalAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getDate = () => {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    setDate(date_raw);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    getTotal();
  });

  useEffect(() => {
    getDate();
  });

  return (
    <div>
      <>
        <div
          data-w-id="da48b9bb-a2a5-ae92-ef12-0e89dd32e770"
          style={{ opacity: 1 }}
          className="preloader-2"
        >
          <div className="text-block-3">Loading...</div>
        </div>
        <div className="section wf-section">
          {/* Logo cũ */}
          {/* <div className="container-2 w-container">
            <div
              data-w-id="6676c084-3586-63d6-6b9a-ee4baf12ca3a"
              data-animation-type="lottie"
              data-src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de8407bd4b2435ff67760b_Imaginary%20ones%20Text2.json"
              data-loop={1}
              data-direction={1}
              data-autoplay={1}
              data-is-ix2-target={0}
              data-renderer="svg"
              data-default-duration="3.066666666666667"
              data-duration={0}
              className="lottie-animation"
            />
          </div> */}

          {/* Logo mới */}
          <div
            className="cointainer-01 w-container"
            style={{ marginBottom: "50px" }}
          >
            <div id="home-banner">
              {isMobile ? (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src="config/images/migrate/logo-mobile.mp4"
                    data-wf-ignore="true"
                  />
                </video>
              ) : (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source
                    src="config/images/migrate/logo.mp4"
                    data-wf-ignore="true"
                  />
                </video>
              )}
            </div>
          </div>

          <div>
            <section className="container pb-5">
              <div className="row justify-content-center">
                <div
                  className="col-12 col-lg-8"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  id="mint"
                >
                  <div className="row" style={{ color: "#141f85" }}>
                    <div className="col-12 col-lg-12 mb-3">
                      <h1 className="heading pb-4" style={{ fontSize: 35 }}>
                        <span className="special_font">
                          PRESALE APRIL {date}
                        </span>
                      </h1>
                      <h1 className="heading pb-5">
                        MINT YOUR IMAGINARY&nbsp;
                        <span className="special_font">ONES</span>&nbsp;NFT NOW
                      </h1>
                      {/* <img
                        src="config/images/migrate/imaginary_ones.png"
                        className="img-fluid"
                        alt="Imaginary ONES NFT"
                      /> */}
                    </div>
                    <div
                      className="col-12 col-lg-6 mb-3 mint-box"
                      style={{
                        fontSize: 20,
                      }}
                    >
                      <span className="special_font">Price:</span>&nbsp; 0.1 ETH
                      <br />
                      <br />
                      <span className="special_font">Max:</span>&nbsp; 10 NFTs
                      per Wallet
                      <br />
                      <br />
                      <span className="special_font">Supply: </span>&nbsp;
                      <span id="sales"></span>/8888 NFT
                    </div>
                    <div className="col-12 col-lg-6 mb-3 justify-content-center align-self-center">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <button
                            type="button"
                            className="btn btn-danger btn-number rounded-circle"
                            data-type="minus"
                            data-field="quant[1]"
                            style={{ width: 70, height: 70 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                          >
                            <i className="fas fa-minus" />
                          </button>
                        </div>
                        <input
                          type="text"
                          name="quant[1]"
                          className="form-control input-number text-center"
                          defaultValue={1}
                          min={1}
                          max={10}
                          value={mintAmount}
                          style={{
                            background: "none",
                            border: "none",
                            fontSize: 24,
                            color: "#141f85",
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-primary btn-number rounded-circle"
                            data-type="plus"
                            data-field="quant[1]"
                            style={{ width: 70, height: 70 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount();
                            }}
                          >
                            <i className="fas fa-plus" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 mb-3 mint-box">
                      <hr />
                      <div className="row">
                        <div className="col-7">
                          <h3 style={{ fontSize: 22 }}>Total:</h3>
                        </div>
                        <div className="col-5">
                          <h3 className="float-end" style={{ fontSize: 22 }}>
                            <span>{total}</span>{" "}
                            <span className="special_font">ETH</span>
                          </h3>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>

                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg float-end mint-box"
                      style={{ fontWeight: 500 }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Connect MetaMask
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg float-end"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        Mint NFT
                      </button>

                      <div
                        className="mt-3 mint-box"
                        style={{ fontSize: 16, fontWeight: 500 }}
                      >
                        {feedback}
                      </div>
                    </>
                  )}

                  <br />
                  <br />

                  {/* <div
                    id="sale_loader"
                    className="spinner-border special_font align-middle"
                    role="status"
                    style={{ display: "none" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div> */}
                  {/* <div className="mt-3" id="payment_result" /> */}

                  <br />
                  <br />
                  <div className="mint-box" style={{ fontSize: 16 }}>
                    <h3 style={{ fontSize: 20 }}>
                      Rules For Purchasing Imaginary Ones:
                    </h3>
                    <br />
                    <ul
                      className="mb-3 ml-3 text-left"
                      style={{ color: "#141f85", fontWeight: 500 }}
                    >
                      <li style={{ listStyle: "circle" }}>
                        Purchase is available while the Mint button is
                        available.
                      </li>
                      <li style={{ listStyle: "circle" }}>
                        No more than 10 NFTs per wallet.
                      </li>
                      <li style={{ listStyle: "circle" }}>
                        When buying more than 2 NFTs, one of the rare
                        collections is given as a gift for the main purchase.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="container-6 w-container">
            <div className="div-block-10">
              <div className="div-block-4">
                <a
                  href="https://twitter.com/Imaginary_Ones"
                  target="_blank"
                  className="w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace77a6f008791bdb3_Twitter.svg"
                    loading="lazy"
                    alt=""
                    className="image-5"
                  />
                </a>
              </div>
              <div className="div-block-4-copy">
                <a
                  href="https://www.instagram.com/theimaginaryones"
                  target="_blank"
                  className="w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace4096b087253acf1_IG.svg"
                    loading="lazy"
                    alt=""
                    className="image-5"
                  />
                </a>
              </div>
              <div className="div-block-4">
                <img
                  src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4aca88d886d3bdbe1fa_Discord.svg"
                  loading="lazy"
                  alt=""
                  className="icon-discord"
                />
              </div>
              <div className="div-block-8">
                <img
                  src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61dee9c4de303fb396df7c71_OS.svg"
                  loading="lazy"
                  alt=""
                  className="icon-discord"
                />
              </div>
            </div>
          </div>
          <div className="cointainer-01 w-container">
            <img
              className="image-2"
              src="config/images/migrate/banner.png"
              width={960}
              alt=""
              sizes="100vw"
              data-w-id="55f7994f-349b-fcdc-e8dd-312c8df06271"
              loading="lazy"
              srcSet="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f4887d28293a861dd0c0e5_Website%20Banner%20(00041)%201%20(0-00-00-00)-p-500.png 500w, https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f4887d28293a861dd0c0e5_Website%20Banner%20(00041)%201%20(0-00-00-00)-p-800.png 800w, https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f4887d28293a861dd0c0e5_Website%20Banner%20(00041)%201%20(0-00-00-00)-p-1080.png 1080w, https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f4887d28293a861dd0c0e5_Website%20Banner%20(00041)%201%20(0-00-00-00)-p-1600.png 1600w, https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f4887d28293a861dd0c0e5_Website%20Banner%20(00041)%201%20(0-00-00-00).png 1920w"
            />
          </div>
        </div>
        <div className="section-4 wf-section">
          <div className="w-container">
            <h1 className="heading-4">Imaginary Ones</h1>
          </div>
          <div className="container-6 w-container">
            <div className="text-block-vision">
              <strong className="bold-text">
                Imaginary Ones is a delightful 3D art with an initial drop of
                8888 unique NFTs on the Ethereum network.
                <br />
                The project believes in using art to spread love, positivity,
                and creativity.
                <br />‍<br />
                Created by Cmttat, a renowned artist based in Singapore.
                <br />
                The team behind Imaginary Ones has more than a decade of
                experience working with
                <br />
                clients like Nike, Apple, Samsung, Heineken, Spotify, L'oreal,
                etc.
              </strong>
            </div>
          </div>
        </div>
        <div className="section-2 wf-section">
          <div
            data-poster-url="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de98c35a7bb673b5956c8c_WalksHD2-poster-00001.jpg"
            data-video-urls="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de98c35a7bb673b5956c8c_WalksHD2-transcode.mp4,https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de98c35a7bb673b5956c8c_WalksHD2-transcode.webm"
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="background-video-2 w-background-video w-background-video-atom"
          >
            <video
              autoPlay
              loop
              style={{
                backgroundImage:
                  "url(_https_/uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de98c35a7bb673b5956c8c_WalksHD2-poster-00001.html)",
              }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source
                src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de98c35a7bb673b5956c8c_WalksHD2-transcode.mp4"
                data-wf-ignore="true"
              />
              <source
                src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61de98c35a7bb673b5956c8c_WalksHD2-transcode.webm"
                data-wf-ignore="true"
              />
            </video>
          </div>
        </div>
        <div className="section-4-copy wf-section">
          <div className="container-10 w-container">
            <h1 className="heading-4">Manifesto</h1>
          </div>
          <div className="container-6 w-container">
            <div className="text-block-vision">
              <strong className="bold-text">
                When we were young, we dreamed of being superheroes, and
                anything was possible.
                <br />
                As time passed, the reality of life robbed us of our ambitions.
                <br />
                <br />
                Imaginary Ones dream to re-ignite that spark again. <br />
                Get creative, search back the passion you genuinely enjoyed.{" "}
                <br />‍
                <br />
                Welcome to the Imaginary World, <br />
                where we dream, learn, and play together.
                <br />
                <br />
                "Laughter is timeless, imagination has no age, and dreams are
                forever."
                <br />– Walt Disney
              </strong>
            </div>
          </div>
        </div>
        <div className="section-2-copy wf-section">
          <div
            data-poster-url="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f10d65be9fd47b6423e99f_WalkTgt-poster-00001.jpg"
            data-video-urls="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f10d65be9fd47b6423e99f_WalkTgt-transcode.mp4,https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f10d65be9fd47b6423e99f_WalkTgt-transcode.webm"
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="background-video-2-copy w-background-video w-background-video-atom"
          >
            <video
              autoPlay
              loop
              style={{
                backgroundImage:
                  "url(_https_/uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f10d65be9fd47b6423e99f_WalkTgt-poster-00001.html)",
              }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source
                src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f10d65be9fd47b6423e99f_WalkTgt-transcode.mp4"
                data-wf-ignore="true"
              />
              <source
                src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f10d65be9fd47b6423e99f_WalkTgt-transcode.webm"
                data-wf-ignore="true"
              />
            </video>
          </div>
        </div>
        <div className="section-4-values wf-section">
          <div className="container-9-copy w-container">
            <div
              id="w-node-_560823c9-198b-6d00-e290-ba77efc18cbd-b8f564a7"
              className="div-block-9-copy"
            >
              <h1 className="heading-values">Imaginary Values</h1>
              <div className="text-block-values">
                <strong className="bold-text-2">Good Vibes Only</strong>
                <strong className="bold-text">
                  {" "}
                  <br />
                  Be someone you love to hang out with.
                  <br />‍<br />
                  <br />
                </strong>
                <strong className="bold-text-3">Dream Together</strong>
                <strong className="bold-text">
                  <br />
                  "A dream you dream alone is only a dream. <br />A dream you
                  dream together is reality." <br />- John Lennon
                  <br />
                  <br />
                  <br />
                </strong>
                <strong className="bold-text-4">
                  Smile Like a Child
                  <br />‍
                </strong>
                <strong className="bold-text">
                  A genuine smile is the most contagious.
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div className="section-4-copy-copy wf-section">
          <div className="w-container">
            <div>
              <h1 className="heading-roadmap">Roadmap Teaser</h1>
            </div>
          </div>
          <div className="container-9 w-container">
            <div
              id="w-node-_694d8016-cd42-df1c-19d8-13974dfddc3c-b8f564a7"
              className="div-block-9"
            >
              <img
                src="config/images/migrate/61f108edc32e1d6470485d7b_1%20(2).gif"
                loading="lazy"
                alt=""
                className="image-6"
              />
              <h1 className="roadmap-1">
                Phase 1:
                <br />
                Imaginations Run Wild
              </h1>
              <div className="roadmap-phases">
                First drop
                <br />
                Community building
              </div>
            </div>
            <div
              id="w-node-e1d35c15-479b-37cb-1ed3-32b3158731ff-b8f564a7"
              className="div-block-9"
            >
              <img
                src="config/images/migrate/61f104e6c32e1dbe2a484f2e_2.gif"
                loading="lazy"
                alt=""
                className="image-6"
              />
              <h1 className="roadmap-1">
                Phase 2:
                <br />
                Imaginary Culture
              </h1>
              <div className="roadmap-phases">
                Second drop
                <br />
                Brand collabs
                <br />
              </div>
            </div>
            <div
              id="w-node-_22618e8d-fb05-1a12-d631-05bedd89e121-b8f564a7"
              className="div-block-9"
            >
              <img
                src="config/images/migrate/61f1074797255114c317b6ea_3.gif"
                loading="lazy"
                alt=""
                className="image-6"
              />
              <h1 className="roadmap-1">
                Phase 3:
                <br />
                Imaginary World
              </h1>
              <div className="roadmap-phases">
                Metaverse
                <br />
                Dream, learn &amp; play together
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="section-3 wf-section">
          <div className="w-container">
            <div>
              <h1 className="heading-6">Meet The Imaginary Team</h1>
            </div>
          </div>
          <div className="container-5 w-container">
            <div
              id="w-node-_379d47f8-a8ea-7607-4551-2512a239761f-b8f564a7"
              className="div-block-2"
            >
              <img
                src="config/images/migrate/61deb452389b4e7e55bb47c4_Cmt-Profile.gif"
                loading="lazy"
                alt=""
                className="image-3"
              />
              <h2 className="heading-3">Cmttat</h2>
              <div className="text-block">
                <strong>
                  Clement
                  <br />
                </strong>
                Co-founder of Imaginary Ones, <br />
                OFFSET &amp; OFFEO
                <br />
                Creator
              </div>
              <div className="div-block-4">
                <a
                  href="https://twitter.com/cmttat"
                  target="_blank"
                  className="w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace77a6f008791bdb3_Twitter.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/cmttat/"
                  target="_blank"
                  className="link-block w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace4096b087253acf1_IG.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
              </div>
            </div>
            <div
              id="w-node-_2b2bee16-302b-8079-b610-3b45f1f36d37-b8f564a7"
              className="div-block-3"
            >
              <img
                src="config/images/migrate/61deb462d03dca701526e1c2_David-Profile.gif"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <h2 className="heading-3">Gentle Whale</h2>
              <div className="text-block">
                <strong>David</strong>
                <br />
                Co-founder of Imaginary Ones, <br />
                OFFSET &amp; OFFEO
                <br />
                Strategist
              </div>
              <div className="div-block-4">
                <a
                  href="https://twitter.com/heygentlewhale"
                  target="_blank"
                  className="w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace77a6f008791bdb3_Twitter.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/gentlewhale/"
                  target="_blank"
                  className="link-block w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace4096b087253acf1_IG.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="container-8 w-container">
            <div
              id="w-node-_695ee5fa-5020-fec5-6c33-153aaf655651-b8f564a7"
              className="div-block-2"
            >
              <img
                src="config/images/migrate/61f0d3c8168a0cf967b4f8b1_Greg-Profile-2.gif"
                loading="lazy"
                alt=""
                className="image-3"
              />
              <h2 className="heading-3">Nine&nbsp;Foot&nbsp;Table</h2>
              <div className="text-block">
                <strong>
                  Gregory
                  <br />
                </strong>
                Managing Partner
                <br />
                Imaginary Ones, OFFSET &amp; OFFEO
                <br />
                Artist &amp;&nbsp;Head of Partnerships
              </div>
              <div className="div-block-4">
                <a
                  href="https://twitter.com/NineFootTable"
                  target="_blank"
                  className="w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace77a6f008791bdb3_Twitter.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/gregpoon/"
                  target="_blank"
                  className="link-block-ig w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace4096b087253acf1_IG.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
              </div>
            </div>
            <div
              id="w-node-_695ee5fa-5020-fec5-6c33-153aaf655663-b8f564a7"
              className="div-block-3"
            >
              <img
                src="config/images/migrate/61f0d3c78b1df71081af98e1_Caleb-Profile.gif"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <h2 className="heading-3">Kbby</h2>
              <div className="text-block">
                <strong>Caleb</strong>
                <br />
                Operations Director
                <br />
                Imaginary Ones, OFFSET &amp; OFFEO
                <br />
                Head of Community
              </div>
              <div className="div-block-4">
                <a
                  href="https://twitter.com/kbbyhoon"
                  target="_blank"
                  className="w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace77a6f008791bdb3_Twitter.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/kbby.hoon/"
                  target="_blank"
                  className="link-block w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace4096b087253acf1_IG.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
              </div>
            </div>
            <div
              id="w-node-e24a475a-f331-bbc2-3536-e2cf74259e93-b8f564a7"
              className="div-block-3"
            >
              <img
                src="config/images/migrate/61f0d3c8ccb99e4811773980_Jerome-Profile.gif"
                loading="lazy"
                alt=""
                className="image-4"
              />
              <h2 className="heading-3">Mighty Mouse</h2>
              <div className="text-block">
                <strong>Jerome</strong>
                <br />
                Chief Technology Officer
                <br />
                Imaginary Ones, OFFSET &amp; OFFEO
                <br />
                Technology/Contracts
              </div>
              <div className="div-block-4">
                <a href="#" className="link-block-2 w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace77a6f008791bdb3_Twitter.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
                <a
                  href="https://www.instagram.com/jeromekwek/"
                  target="_blank"
                  className="link-block w-inline-block"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61deb4ace4096b087253acf1_IG.svg"
                    loading="lazy"
                    alt=""
                    className="image-5-copy"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer preloader wf-section">
          <div className="container-7 w-container">
            <div>
              <h1 className="heading-2-copy">Contact us</h1>
              <div className="text-block-2">
                We can't reply to all emails but all good vibes are appreciated
              </div>
            </div>
            <div className="div-block-5">
              <a
                href="mailto:hello@imaginaryones.com"
                className="w-inline-block"
              >
                <div className="text-block-2-copy">hello@imaginaryones.com</div>
              </a>
            </div>
          </div>
          <div className="container-6 w-container">
            <div className="div-block-4">
              <a
                href="https://twitter.com/Imaginary_Ones"
                target="_blank"
                className="w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f11140f52ee064ce6490c9_61deb4ace77a6f008791bdb3_Twitterw.svg"
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </a>
            </div>
            <div className="div-block-4-copy">
              <a
                href="https://www.instagram.com/theimaginaryones"
                target="_blank"
                className="w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f11140890ad9fbefb3ed0a_61deb4ace4096b087253acf1_IGw.svg"
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </a>
            </div>
            <div className="div-block-4">
              <img
                src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f1114094ace3452772d676_61deb4aca88d886d3bdbe1fa_Discordw.svg"
                loading="lazy"
                alt=""
                className="icon-discord"
              />
            </div>
            <div className="div-block-8">
              <a
                href="https://opensea.io/collection/theimaginaryones"
                target="_blank"
                className="w-inline-block"
              >
                <img
                  src="https://uploads-ssl.webflow.com/61de7298d989570f5853dd20/61f1114026c86c2237995e16_61dee9c4de303fb396df7c71_OSw.svg"
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </a>
            </div>
          </div>
          <div className="container-7-copy w-container">
            <div className="div-block-6">
              <div className="text-block-2-copy-copy">
                © 2022 Imaginary&nbsp;Ones
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default App;
